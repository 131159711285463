import { Component } from "@angular/core";

@Component({
    standalone: true,
    selector: '[app-right-align]',
    styles: `
    :host {
        width: 100%;
        display: flex;
        justify-content: end;
        gap: 0.5rem;
    }`,
    template: '<ng-content></ng-content>'
})
export class RightAlignComponent {}