import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
  ],
  selector: "app-interval-usage-history-modal",
  styles: ``,
  template: `
    <div app-modal-header>Usage History</div>
    <div app-modal-body>
      <div app-labeled-property label="Service Point">Commin Soon!</div>
      <div app-labeled-property label="Date">Commin Soon!</div>
      <div app-labeled-property label="Time">Commin Soon!</div>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class IntervalUsageHistoryModalComponent {
  activeModal = inject(NgbActiveModal);
  referenceDataService = inject(ReferenceDataService);

  init(servicePointId: string, date: string, time: string) {
    console.log({ servicePointId, date, time });
  }

  onClose() {
    this.activeModal.dismiss();
  }
}
