import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-modal-header]",
  host: {
    class: "modal-header",
  },
  template: `<h4 class="modal-title"><ng-content></ng-content></h4>`,
  styles: `
    :host {
        display: flex;
        justify-content: center;
    }
  `,
})
export class ModalHeaderComponent {}
