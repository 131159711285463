import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AllPipe } from "src/app/shared/pipes/all.pipe";
import { SomePipe } from "src/app/shared/pipes/some.pipe";

@Component({
    standalone: true,
    selector: '[app-select-menu-all-displayed-list-item]',
    imports: [AllPipe, SomePipe],
    template: `
        <button (click)="handleClick()">
            <span class="display-multi" [class.selected]="selected | some:items:identity" [class.all]="selected | all:items:identity">&nbsp;</span>
            <span class="display-value">
                Select All Displayed
            </span>
        </button>
    `,
    styles: `
        :host{
            margin: 0;
            padding: 0;
            
            &.selected {
                background: gainsboro;
            }

            &:hover {
                background: lightgray;
            }
        }

        button {
            position: relative;
            display: flex;
            justify-content: start;
            align-items: start;
            gap: 0.75rem;
            width: 100%;
            padding: 0.75rem 0 0.75rem 1rem;
            margin: 0;
            background: none;
            border: none;
            text-align: start;
            font-size: 0.9rem;
            line-height: 1.25rem;
        }

        .display {
            &-value {
                position: relative;
                white-space: wrap;
                width: 100%;
                font-weight: 500
            }

            &-multi {
                position: relative;
                min-width: 1.25rem;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid black;
                border-radius: 0.25rem;

                &.selected {
                    background: var(--active-blue);
                    &::before {
                        display: block;
                        position: absolute;
                        content: ' ';
                        width: 1rem;
                        height: 0.25rem;
                        left: 0.1rem;
                        top: 0.25rem;
                        box-shadow: 0px 3px 1px white;
                    }
                }

                &.all.selected {
                    background: var(--active-blue);
                    &::before {
                        display: block;
                        position: absolute;
                        content: ' ';
                        width: 1rem;
                        height: 0.6rem;
                        transform: rotate(-50deg);
                        left: 0.08rem;
                        top: -0.1rem;
                        box-shadow: -3px 3px 1px white;
                    }
                }
            }
        }
    `
})
export class SelectMenuAllDisplayedListItemComponent<T> {
    @Input({required: true})
    items: T[];
    @Input({required: true})
    selected: T[];
    @Input({required: true})
    identity: (v: T) => string;


    @Output()
    select = new EventEmitter<T[]>()
    @Output()
    clear = new EventEmitter<T[]>()

    handleClick() {
        const mapped: Record<string, T> = this.selected.reduce((acc, s) => ({...acc, [this.identity(s)]: s}), {});
        const allSelected = this.items.every(i => !!mapped[this.identity(i)]);
        if(allSelected) {
            this.clear.emit(this.items);
        } else {
            this.select.emit(this.items.filter(i => !mapped[this.identity(i)]));
        }
    }
}