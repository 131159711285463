import { Injectable } from "@angular/core";
import { isEqual } from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
} from "rxjs";

export type TablePageState = {
  pageNumber: number;
  pageSize: number;
  total: number;
};

@Injectable({
  providedIn: "any",
})
export class TablePageService {
  public pageNumber$ = new BehaviorSubject(0);
  public pageSize$ = new BehaviorSubject(1000);
  public total$ = new BehaviorSubject(0);
  public state$ = <Observable<TablePageState>>combineLatest([
    this.pageNumber$,
    this.pageSize$,
    this.total$,
  ]).pipe(
    map(([pageNumber, pageSize, total]) => ({
      pageNumber,
      pageSize,
      total,
    })),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );
  public params$ = <Observable<Omit<TablePageState, 'total'>>>this.state$.pipe(
    map(state => ({
        pageNumber: state.pageNumber,
        pageSize: state.pageSize,
    })),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );
}
