import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  Observable,
  of,
  scan,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from "rxjs";

export type FilterSearchMeta = {
  searchTerm: string;
  searching: boolean;
  paging: boolean;
  hasMore: boolean;
  error: boolean;
  count: number;
};

export class FilterSearchState<T> {
  private pageSize = 1000;

  private searchTerm$ = new Subject<string>();
  private pageTrigger$ = new Subject<void>();
  private metaTrigger$ = new BehaviorSubject<Partial<FilterSearchMeta>>({
    searchTerm: "",
    searching: false,
    paging: false,
    hasMore: false,
    error: false,
    count: 0,
  });

  public results$ = this.searchTerm$.asObservable().pipe(
    tap((searchTerm) => {
      shareReplay(1),
      distinctUntilChanged(),
      this.metaTrigger$.next({
        searchTerm: searchTerm,
        searching: true,
        hasMore: true,
        error: false,
        count: 0,
      });
    }),
    switchMap((searchTerm) =>
      this.pageTrigger$
        .asObservable()
        .pipe(
          tap(() => {
            this.metaTrigger$.next({
              paging: true,
            });
          }),
          startWith(void 0)
        )
        .pipe(
          scan((pageNumber) => pageNumber + 1, -1),
          distinctUntilChanged(),
          shareReplay(1),
          switchMap((pageNumber) =>
            this.fetch(searchTerm, pageNumber, this.pageSize)
          ),
          tap(() => {
            this.metaTrigger$.next({
              searching: false,
              paging: false,
              error: false,
            });
          }),
          scan((acc, arr) => [...acc, ...arr], <T[]>[]),
          startWith(<T[]>[])
        )
    ),
    catchError((e) => {
      this.metaTrigger$.next({
        searching: false,
        paging: false,
        error: true,
      });
      return of([]);
    }),
    tap((results) => {
        this.metaTrigger$.next({
            hasMore: results.length % this.pageSize === 0,
            count: results.length,
        })
    }),
    shareReplay(1)
  );

  public meta$ = this.metaTrigger$.asObservable().pipe(
    scan((acc, next) => ({ ...acc, ...next }), <FilterSearchMeta>{
      searchTerm: "",
      searching: false,
      paging: false,
      hasMore: false,
      error: false,
      count: 0,
    })
  );

  constructor(
    private fetch: (
      searchTerm: string,
      pageNumber: number,
      pageSize: number
    ) => Observable<T[]>
  ) {}

  public search(term: string) {
    this.searchTerm$.next(term ?? '');
  }

  public page() {
    this.pageTrigger$.next(void 0);
  }
}
