import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { catchError, of, throwError } from "rxjs";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { IntervalUsageResponse } from "src/app/shared/models/intervalUsageResponse";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  withHttpParams,
  pagination,
  sort,
  filter,
} from "src/app/shared/utilities/http-params";

@Injectable({
  providedIn: "root",
})
export class IntervalUsageService {
  constructor(private http: HttpClient) {}

  getIntervalUsage(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http
      .get<IntervalUsageResponse>("interval_usage/", {
        params,
        headers: {
          NOLOADING: "true",
          EXPECT_FAILURE: "true",
        },
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error(
        "There is an issue with the client or network:",
        error.error
      );
    } else {
      console.error("Server-side error: ", error.error);
    }
    return of({
      results: new Array(1000)
        .fill(0)
        .map((_, i) =>
          new Array(24)
            .fill(<IntervalUsage>{
              service_point_id: `${i}`.padStart(16, "0"),
              operating_date: moment(new Date()).startOf("d").add(i, "d").toISOString(),
              market_interval: "",
              measurement_value: 0,
              last_update_time: moment(new Date())
                .startOf("d")
                .add(1, "d")
                .toISOString(),
              last_updated_by: "q186994",
            })
            .map((v, i2) => ({
              ...v,
              measurement_value: i2,
              market_interval: moment(new Date()).startOf("d").add(i2, "h").toISOString(),
            }))
        )
        .flat(),
      total: 24 * 1000,
    });

    // return throwError(
    //   () =>
    //     new Error(
    //       "Cannot retrieve interval usage from the server. Please try again."
    //     )
    // );
  }
}
