import { Component, inject, TemplateRef, ViewChild } from "@angular/core";
import { SpaOverrideAction } from "./spah-override.types";
import {
  catchError,
  firstValueFrom,
  of,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpaOverride } from "src/app/shared/models/spaOverride";
import { SpaOverrideService } from "./spah-override.service";
import { ToastService } from "../toasts/toasts.service";
import { ServicePoint } from "src/app/shared/models/servicePoint";

@Component({
  selector: "app-spah-overrides",
  template: `
    <app-page-header title="Service Point Overrides">
      <button class="action-btn" (click)="createNew()" [disabled]="dataLoading">
        Create <mat-icon svgIcon="add_circle" />
      </button>
    </app-page-header>
    <app-page-content>
      <app-spah-overrides-table
        [actions$]="actions$"
        (loading)="onDataLoading($event)"
        (create)="onCreate($event)"
        (update)="onUpdate($event)"
        (delete)="onDelete($event)"
      />

      <ng-template #modal let-modal>
        <div class="modal-header">
          <h4 class="modal-title">{{ modalTitle }}</h4>
        </div>
        <div class="modal-body">
          <p>{{ modalMessage }}</p>
          <app-service-point-attributes-confirmation-table
            [loading]="confirmationLoading || saving"
            [data]="confirmationData"
          />
        </div>
        <div class="modal-footer">
          <button
            class="confirm-btn error"
            (click)="modalCommit()"
            [disabled]="confirmationLoading || saving"
          >
            {{ modalCommitMessage }}
          </button>
          <button
            class="confirm-btn"
            (click)="modalClose()"
            [disabled]="confirmationLoading || saving"
          >
            {{ modalCloseMessage }}
          </button>
        </div>
      </ng-template>
    </app-page-content>
  `,
  styles: `
  .confirm-btn{
    padding: 0.5rem 1rem;
    border: none;
    background: white;
    text-align: center;
    border: 1px solid black;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    line-height: 1.25rem;

    &:focus {
      outline: none;
    }

    &.error {
      border-color: var(--error-red);
      color: var(--error-red)
    }
  }
  `,
})
export class SpahOverridesComponent {
  modalService = inject(NgbModal);
  spaOverrideService = inject(SpaOverrideService);
  toastService = inject(ToastService);

  @ViewChild("modal")
  private modalRef: TemplateRef<any>;

  // table
  public actions$ = new ReplaySubject<SpaOverrideAction>(1);
  public dataLoading = false;
  public saving = false;
  public confirmationLoading = false;
  public confirmationError = "";
  public confirmationData: ServicePoint[] = [];

  // Modal
  public modalLoading = false;
  public modalTitle = "";
  public modalMessage = "";
  public modalCloseMessage = "";
  public modalCommitMessage = "";
  public modalClose = (): void => void 0;
  public modalCommit = async (): Promise<void> => void 0;

  createNew() {
    this.actions$.next({
      type: "CREATE",
    });
  }

  onDataLoading(isLoading: boolean) {
    this.dataLoading = isLoading;
  }

  async onCreate(data: Partial<SpaOverride>) {
    this.modalLoading = true;
    this.modalTitle = "Confirm Creation";
    this.modalMessage =
      "Below are the service points as they would appear after this change takes affect. Please take a moment to review this change";
    this.modalCloseMessage = "Close";
    this.modalCommitMessage = "Confirm & Create";
    this.modalClose = (() => this.modalService.dismissAll()).bind(this);
    this.modalCommit = (() => this.confirmCreate(data)).bind(this);
    this.confirmationLoading = true;
    this.confirmationError = "";
    this.confirmationData = [];
    this.openModal();
    await firstValueFrom(
      this.spaOverrideService.createOverride(data, true).pipe(
        tap((res) => {
          console.log(res);
          this.confirmationData = res.results;
          this.confirmationLoading = false;
        }),
        catchError(() => {
          this.confirmationData = [];
          this.confirmationLoading = false;
          this.confirmationError =
            "Failed to determine changes! Please try again.";
          return of(undefined);
        })
      )
    );
  }

  async onUpdate(data: {
    id: string;
    effective_date: Date;
    data: Partial<SpaOverride>;
  }) {
    this.modalLoading = true;
    this.modalTitle = "Confirm Updates";
    this.modalMessage =
      "Below are the service points as they would appear after this change takes affect. Please take a moment to review this change";
    this.modalCloseMessage = "Close";
    this.modalCommitMessage = "Confirm & Update";
    this.modalClose = (() => this.modalService.dismissAll()).bind(this);
    this.modalCommit = (() => this.confirmUpdate(data)).bind(this);
    this.confirmationLoading = true;
    this.confirmationError = "";
    this.confirmationData = [];
    this.openModal();
    await firstValueFrom(
      this.spaOverrideService
        .updateOverride(data.id, data.effective_date, data.data, true)
        .pipe(
          tap((res) => {
            this.confirmationData = res.results;
            this.confirmationLoading = false;
          }),
          catchError(() => {
            this.confirmationData = [];
            this.confirmationLoading = false;
            this.confirmationError =
              "Failed to determine changes! Please try again.";
            return of(undefined);
          })
        )
    );
  }

  async onDelete(data: {
    id: string;
    effective_date: Date;
  }) {
    this.modalLoading = true;
    this.modalTitle = "Confirm Deletion";
    this.modalMessage =
      "Below are the service points as they would appear after this change takes affect. Please take a moment to review this change";
    this.modalCloseMessage = "Cancel";
    this.modalCommitMessage = "Confirm & Delete";
    this.modalClose = (() => this.modalService.dismissAll()).bind(this);
    this.modalCommit = (() => this.confirmDelete(data)).bind(this);
    this.confirmationLoading = true;
    this.confirmationError = "";
    this.confirmationData = [];
    this.openModal();
    await firstValueFrom(
      this.spaOverrideService
        .deleteOverride(data.id, data.effective_date, true)
        .pipe(
          tap((res) => {
            this.confirmationData = res.results;
            this.confirmationLoading = false;
          }),
          catchError(() => {
            this.confirmationData = [];
            this.confirmationLoading = false;
            this.confirmationError =
              "Failed to determine changes! Please try again.";
            return of(undefined);
          })
        )
    );
  }

  async confirmCreate(data: Partial<SpaOverride>): Promise<any> {
    return firstValueFrom(
      of(data).pipe(
        tap(() => {
          this.saving = true;
        }),
        switchMap((data) =>
          this.spaOverrideService.createOverride(data, false)
        ),
        take(1),
        tap(() => {
          this.saving = false;
          this.modalClose();
          this.actions$.next({ type: "REFRESH" });
        }),
        catchError(() => {
          this.saving = false;
          this.toastService.show({
            type: "error",
            message: "Could not save SPAH Override! Please try again.",
          });
          return of(undefined);
        })
      )
    );
  }

  async confirmUpdate({
    id,
    effective_date,
    data,
  }: {
    id: string;
    effective_date: Date;
    data: Partial<SpaOverride>;
  }): Promise<any> {
    return firstValueFrom(
      of(data).pipe(
        tap(() => {
          this.saving = true;
        }),
        switchMap((data) =>
          this.spaOverrideService.updateOverride(
            id,
            effective_date,
            data,
            false
          )
        ),
        take(1),
        tap(() => {
          this.saving = false;
          this.modalClose();
          this.actions$.next({ type: "REFRESH" });
        }),
        catchError(() => {
          this.saving = false;
          this.toastService.show({
            type: "error",
            message: "Could not save SPAH Override! Please try again.",
          });
          return of(undefined);
        })
      )
    );
  }

  async confirmDelete({
    id,
    effective_date,
  }: {
    id: string;
    effective_date: Date;
  }): Promise<any> {
    return firstValueFrom(
      of(null).pipe(
        tap(() => {
          this.saving = true;
        }),
        switchMap(() =>
          this.spaOverrideService.deleteOverride(
            id,
            effective_date,
            false
          )
        ),
        take(1),
        tap(() => {
          this.saving = false;
          this.modalClose();
          this.actions$.next({ type: "REFRESH" });
        }),
        catchError(() => {
          this.saving = false;
          this.toastService.show({
            type: "error",
            message: "Could not save SPAH Override! Please try again.",
          });
          return of(undefined);
        })
      )
    );
  }

  private openModal() {
    this.modalService.open(this.modalRef, {
      windowClass: "confirmation-modal",
      centered: true,
    });
  }
}
