import { NumberDirective } from "./shared/directives/numbers-only.directive";
import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./public/header/header.component";
import { HomeComponent } from "./public/home/home.component";
import { PhoneMaskDirective } from "./shared/directives/phone-mask.diretive";
import { FaqComponent } from "./public/faq/faq.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CorrelationInterceptor } from "./shared/interceptors/correlation-interceptor";
import { DollarAmountPipe } from "./shared/pipes/dollarAmount.pipe";
import { SortDirective } from "./shared/directives/sort.directive";
import { AccountNumberPipe } from "./shared/pipes/accountNumber.pipe";
import { SearchFilterPipe } from "./shared/pipes/search-filter";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { SpahOverridesComponent } from './public/spah-overrides/spah-overrides.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from "@angular/material/paginator";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTreeModule } from '@angular/material/tree';
import { NavBarComponent } from "./public/nav-bar/nav-bar.component";
import { LoadingSpinnerComponent } from "./public/loading-spinner/loading-spinner.component";
import { LoadingSpinnerInterceptor } from "./shared/interceptors/loading-spinner-interceptor";
import { MatFormFieldModule } from "@angular/material/form-field";
import { EntityManagementComponent } from './public/entities/entity-management.component';
import { EntitiesComponent } from './public/entities/entities/entities.component';
import { EntityTypesComponent } from './public/entities/entity-types/entity-types.component';
import { EntityTypeGroupsComponent } from './public/entities/entity-type-groups/entity-type-groups.component';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { OneuiframeworkModule } from "@/oneuiframework";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthCheckInterceptor } from "./shared/interceptors/auth-check-interceptor";
import { ScalarUsageComponent } from "./public/scalar-usage/scalar-usage.component";
import { IntervalUsageComponent } from "./public/interval-usage/interval-usage.component";
import { ServicePointsComponent } from "./public/service-points/service-points.component";
import { ProfileSegmentComponent } from "./public/profile-segment/profile-segment.component";
import { ScalarProfilingComponent } from "./public/scalar-profiling/scalar-profiling.component";
import { BaseUrlInterceptor } from "./shared/interceptors/base-url-interceptor";
import { PlanningYearComponent } from "./public/planning-year/planning-year.component";
import { CoincidentPeakComponent } from "./public/coincident-peak/coincident-peak.component";
import { SelectComponent } from "./public/select/select.component";
import { PageHeaderComponent } from "./public/page-header/page-header.component";
import { PageContentComponent } from "./public/page-content/page-content.component";
import { MDMAAggregationsComonent } from "./public/mdma-aggegations/mdma-aggregations.component";
import { SpinnerComponent } from "./public/spinner/spinner.component";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastsComponent } from "./public/toasts/toasts.component";
import { MDMAAggregationsTableComponent } from "./public/table/mdma-aggregations-table.component";
import { TableLoadingOverlayComponent } from "./public/table/overlays/table-loading-overlay.component";
import { ServicePointAttributesTableComponent } from "./public/table/service-point-attributes-table.component";
import { HeadersInterceptor } from "./shared/interceptors/headers-interceptor";
import { QuickDatePickerComponent } from "./public/quick-date-picker/quick-date-picker.component";
import { SpahOverridesTableComponent } from "./public/table/spah-overrides-table.component";
import { FlagCellComponent } from "./public/table/cells/flag-cell.component";
import { StringPipe } from "./shared/pipes/string.pipe";
import { BasicEntityRefCellComponent } from "./public/table/cells/basic-entity-code-cell.component";
import { ServicePointCellComponent } from "./public/table/cells/service-point-cell.component";
import { DateCellComponent } from "./public/table/cells/date-cell.component";
import { DateStructPipe } from "./shared/pipes/dateStruct.pipe";
import { ToDatePipe } from "./shared/pipes/toDate.pipe";
import { FlagHeaderComponent } from "./public/table/headers/flag-header.component";
import { SelectMenuListItemComponent } from "./public/table/filters/select-menu-list-item/select-menu-list-item.component";
import { ValuesPipe } from "./shared/pipes/values.pipe";
import { PropPipe } from "./shared/pipes/propPipe.pipe";
import { SelectMenuListPagingComponent } from "./public/table/filters/select-menu-list-paging-item/select-menu-list-paging-item.component";
import { SelectMenuListErrorComponent } from "./public/table/filters/select-menu-list-error-item/select-menu-list-error-item.component";
import { SelectMenuListloadingComponent } from "./public/table/filters/select-menu-list-loading-item/select-menu-list-loading-item.component";
import { SelectMenuListComponent } from "./public/table/filters/select-menu-list/select-menu-list.component";
import { DatePickerButtonComponent } from "./public/date-picker-button/date-picker-button.component";
import { ServicePointAttributesConfirmationTableComponent } from "./public/table/service-point-attributes-confirmation-table.component";
import { TableConfirmActionComponent } from "./public/table/table-confirm-action/table-confirm-action.component";
import { BasicHeaderComponent } from "./public/table/headers/basic-header.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        PhoneMaskDirective,
        FaqComponent,
        DollarAmountPipe,
        SortDirective,
        NumberDirective,
        AccountNumberPipe,
        SearchFilterPipe,
        SafePipe,
        AccountNumberPipe,
        SpahOverridesComponent,
        NavBarComponent,
        LoadingSpinnerComponent,
        EntityManagementComponent,
        EntitiesComponent,
        EntityTypesComponent,
        EntityTypeGroupsComponent,
        ScalarUsageComponent,
        ProfileSegmentComponent,
        ScalarProfilingComponent,
        PlanningYearComponent,
        CoincidentPeakComponent,
        MDMAAggregationsComonent,
        ToastsComponent,
    ],
    imports: [
    BrowserModule,
    AppRoutingModule,
    OneuiframeworkModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDialogModule,
    AppRoutingModule,
    MatSelectModule,
    MatCheckboxModule,
    DragDropModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatTreeModule,
    ScrollingModule,
    MatFormFieldModule,
    AgGridAngular,
    SelectComponent,
    QuickDatePickerComponent,
    PageHeaderComponent,
    SpinnerComponent,
    NgbToastModule,
    CommonModule,
    TableLoadingOverlayComponent,
    TableConfirmActionComponent,
    MDMAAggregationsTableComponent,
    ServicePointAttributesTableComponent,
    ServicePointAttributesConfirmationTableComponent,
    SpahOverridesTableComponent,
    FlagCellComponent,
    BasicEntityRefCellComponent,
    ServicePointCellComponent,
    DateCellComponent,
    StringPipe,
    DateStructPipe,
    ToDatePipe,
    ValuesPipe,
    PropPipe,
    FlagHeaderComponent,
    BasicHeaderComponent,
    SelectMenuListComponent,
    SelectMenuListloadingComponent,
    SelectMenuListErrorComponent,
    SelectMenuListItemComponent,
    SelectMenuListPagingComponent,
    DatePickerButtonComponent,
    PageContentComponent,
    ServicePointsComponent,
    IntervalUsageComponent,
],
    exports: [PhoneMaskDirective, AccountNumberPipe],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CorrelationInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthCheckInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
        DatePipe,
        CurrencyPipe,
        PercentPipe,
        AccountNumberPipe,
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
