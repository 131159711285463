import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-modal-body]",
  host: {
    class: "modal-body",
  },
  template: `<ng-content></ng-content>`,
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
    }
  `,
})
export class ModalBodyComponent {}
