import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableHeaderSortButtonComponent } from "./table-header-sort-button/table-header-sort-button.component";
import { CurrentSort, TableSortService } from "../services/table-sort.service";
import { Subscription } from "rxjs";
import { TableFilterService } from "../services/table-filter.service";
import { IHeaderParams } from "ag-grid-community";

@Component({
  standalone: true,
  imports: [CommonModule, TableHeaderSortButtonComponent],
  styles: `
        :host {
            position: relative;
            display: grid;
            grid-template-columns: 1fr minmax(0, 1.5rem);
            gap: 0.25rem;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 700;
            width: 100%;
        }
    `,
  template: `
    <button
      app-table-header-sort-button
      [label]="headerName"
      [currentSort]="currentSort"
      (click)="sort($event)"
    ></button>
  `,
})
export class BasicHeaderComponent<DATA extends Record<string, unknown>> {
  private sortService = inject(TableSortService);
  public filterService = inject(TableFilterService);
  private subscription = new Subscription();

  public params: IHeaderParams<DATA>;
  public headerName: string = "";
  public field: string = "";
  public sortable: boolean = false;
  public currentSort: CurrentSort | undefined = undefined;
  public selected: boolean[] = [];

  agInit(params: IHeaderParams<DATA>): void {
    this.refresh(params);
  }

  refresh(params: IHeaderParams<DATA>): boolean {
    this.params = params;
    this.headerName = params.column.getColDef().headerName ?? "";
    this.field = params.column.getColDef().field ?? "";
    this.sortable = params.column.isSortable();
    return true;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.sortService.getCurrentSort(this.field).subscribe((cs) => {
        this.currentSort = cs;
      })
    );
  }

  compareBools(a: boolean, b: boolean) {
    return a === b;
  }

  has(val: boolean) {
    return this.selected.find((s) => s === val) !== undefined;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sort(evt: MouseEvent) {
    if (this.sortable) {
      this.sortService.next({
        name: this.field,
        multi: evt.ctrlKey,
      });
    }
  }
}
