import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    pure: true,
    name: 'some'
})
export class SomePipe<T> implements PipeTransform {
    transform(value: T[], fullList: T[], id: (a:T )=> string) {
        const map: Record<string, T> = value.reduce((acc, v) => ({
            ...acc, [id(v)]: v
        }), {});
        return fullList.some(i => !!map[id(i)]);
    }
}