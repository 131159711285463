import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { RightAlignComponent } from "../../right-align/right-align.component";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    RightAlignComponent,
  ],
  selector: "app-interval-usage-import-modal",
  styles: ``,
  template: `
    <div app-modal-header>Import</div>
    <div app-modal-body>
      <div app-right-align>
        <button class="action-btn" (click)="onUpload()" [disabled]="true">
          Upload Usage
        </button>
        <button
          class="action-btn"
          (click)="onDownloadTemplate()"
          [disabled]="true"
        >
          Download Template
        </button>
      </div>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onImport()" [disabled]="true">
        Import
      </button>
    </div>
  `,
})
export class IntervalUsageImportModalComponent {
  activeModal = inject(NgbActiveModal);

  onCancel() {
    this.activeModal.dismiss();
  }

  onImport() {}

  onUpload() {}

  onDownloadTemplate() {}
}
