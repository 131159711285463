import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  withHttpParams,
  pagination,
  sort,
  filter,
} from "src/app/shared/utilities/http-params";
import { catchError, Observable, of } from "rxjs";
import { SpaOverrideResponse } from "src/app/shared/models/spaOverrideResponse";
import * as moment from "moment";
import { SpaOverride } from "src/app/shared/models/spaOverride";

export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class SpaOverrideService {
  constructor(private http: HttpClient) {}

  createOverride(data: Partial<SpaOverride>, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.post<ServicePointResponse>("spah_override/", {...data, dry_run: dryRun}, {
      headers: {
        NOLOADING: "true",
      },
    });
  }

  updateOverride(id: string, effectiveDate: Date, data: Partial<SpaOverride>, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.patch<ServicePointResponse>(`spah_override/${id}/${moment(effectiveDate).format('YYYY-MM-DDTHH:mm:ss')}`, {...data, dry_run: dryRun}, {
      headers: {
        NOLOADING: "true",
      },
    });
  }

  deleteOverride(id: string, effectiveDate: Date, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.delete<ServicePointResponse>(`spah_override/${id}/${moment(effectiveDate).format('YYYY-MM-DDTHH:mm:ss')}`, {
      headers: {
        NOLOADING: "true",
      },
      params: {
        dry_run: dryRun
      }
    });
  }

  getOverrides(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http.get<SpaOverrideResponse>("spah_override/", {
      params,
      headers: {
        NOLOADING: "true",
      },
    });
  }
}
