import { Component } from "@angular/core";

@Component({
    standalone: true,
    selector: '[app-select-menu-list]',
    styles: `
    :host {
        list-style: none;
        width: 100%;
        max-height: 16rem;
        overflow-y: scroll;
        margin: 0;
        padding: 0;
    }
    `,
    template: '<ng-content></ng-content>'
})
export class SelectMenuListComponent {}