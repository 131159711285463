import { Component, ElementRef, HostListener, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableHeaderSortButtonComponent } from "./table-header-sort-button/table-header-sort-button.component";
import { TableHeaderFilterButton } from "./table-header-filter-button/table-header-filter-button.component";
import { TableHeaderFilterPanelComponent } from "./table-header-filter-panel/table-header-filter-panel.component";
import { SelectMenuListItemComponent } from "../filters/select-menu-list-item/select-menu-list-item.component";
import { SelectMenuListComponent } from "../filters/select-menu-list/select-menu-list.component";
import { CurrentSort, TableSortService } from "../services/table-sort.service";
import { Subscription } from "rxjs";
import { TableFilterService } from "../services/table-filter.service";
import { IHeaderParams } from "ag-grid-community";
import {
  FilterListState,
} from "src/app/shared/utilities/filter-list-state";
import { InListPipe } from "src/app/shared/pipes/inList.pipe";
import { BoolDisplayPipe } from "src/app/shared/pipes/boolDisplay.pipe";

type FlagHeaderComponentProps<T extends Record<string, any>> = {
  filterState?: FilterListState<boolean, T>;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableHeaderSortButtonComponent,
    TableHeaderFilterButton,
    TableHeaderFilterPanelComponent,
    SelectMenuListComponent,
    SelectMenuListItemComponent,
    BoolDisplayPipe,
    InListPipe,
  ],
  styles: `
        :host {
            position: relative;
            display: grid;
            grid-template-columns: 1fr minmax(0, 1.5rem);
            gap: 0.25rem;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 700;
            width: 100%;
        }
    `,
  template: `
    <button
      app-table-header-sort-button
      [label]="headerName"
      [currentSort]="currentSort"
      (click)="sort($event)"
    ></button>

    @if(filterable) {
    <button
      app-table-header-filter-button
      [filterCount]="selected.length"
      (click)="filterService.toggle(field)"
    ></button>
    }

    <div app-table-header-filter-panel [show]="isFilterOpen">
      <ul app-select-menu-list>
        <li
          app-select-menu-list-item
          [value]="true"
          [display]="true | boolDisplay"
          [selected]="true | inList : selected : compareBools"
          [multi]="true"
          (select)="setValue($event)"
        ></li>
        <li
          app-select-menu-list-item
          [value]="false"
          [display]="false | boolDisplay"
          [selected]="false | inList : selected : compareBools"
          [multi]="true"
          (select)="setValue($event)"
        ></li>
      </ul>
    </div>
  `,
})
export class FlagHeaderComponent<DATA extends Record<string, unknown>> {
  private elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
  private sortService = inject(TableSortService);
  public filterService = inject(TableFilterService);

  private subscription = new Subscription();

  public params: IHeaderParams<DATA> & FlagHeaderComponentProps<DATA>;
  public headerName: string = "";
  public field: string = "";
  public sortable: boolean = false;
  public filterable: boolean = false;
  public currentSort: CurrentSort | undefined = undefined;
  public isFilterOpen: boolean = false;
  public selected: boolean[] = [];
  public filterState?: FilterListState<boolean>;

  agInit(params: IHeaderParams<DATA> & FlagHeaderComponentProps<DATA>): void {
    this.refresh(params);
  }

  refresh(
    params: IHeaderParams<DATA> & FlagHeaderComponentProps<DATA>
  ): boolean {
    this.params = params;
    this.headerName = params.column.getColDef().headerName ?? "";
    this.field = params.column.getColDef().field ?? "";
    this.sortable = params.column.isSortable();
    this.filterable = params.column.isFilterAllowed();
    return true;
  }

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.isFilterOpen &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.filterService.toggle(this.field);
    }
  }

  ngOnInit(): void {
    this.filterState = this.params.filterState;
    if (this.filterState) {
      this.subscription.add(
        this.filterState.list$.subscribe((l) => {
          this.selected = l;
        })
      );
      this.subscription.add(
        this.filterState.params$.subscribe((p) => {
          this.filterService.update(p);
        })
      );
    }
    this.subscription.add(
      this.filterService.isOpen(this.field).subscribe((isOpen) => {
        this.isFilterOpen = isOpen;
      })
    );
    this.subscription.add(
      this.sortService.getCurrentSort(this.field).subscribe((cs) => {
        this.currentSort = cs;
      })
    );
  }

  compareBools(a: boolean, b: boolean) {
    return a === b;
  }

  has(val: boolean) {
    return this.selected.find((s) => s === val) !== undefined;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sort(evt: MouseEvent) {
    if (this.sortable) {
      this.sortService.next({
        name: this.field,
        multi: evt.ctrlKey,
      });
    }
  }

  setValue(val: boolean) {
    if (this.filterState) {
      this.has(val)
        ? this.filterState.remove(val)
        : this.filterState.add(val, false);
    }
  }
}
