import { inject, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardPocComponent } from "./public/dashboarding/poc.component";
import { EntityManagementComponent } from "./public/entities/entity-management.component";
import { HomeComponent } from "./public/home/home.component";
import { MDMAAggregationsComonent } from "./public/mdma-aggegations/mdma-aggregations.component";
import { PlanningYearComponent } from "./public/planning-year/planning-year.component";
import { ProfileSegmentComponent } from "./public/profile-segment/profile-segment.component";
import { ScalarProfilingComponent } from "./public/scalar-profiling/scalar-profiling.component";
import { ServicePointsComponent } from "./public/service-points/service-points.component";
import { SpahOverridesComponent } from "./public/spah-overrides/spah-overrides.component";
import { AuthResolver } from "./shared/services/auth-resolver.service";
import { IntervalUsageComponent } from "./public/interval-usage/interval-usage.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {user : () => inject(AuthResolver).resolve()}
  },
  {
    path: 'mdma-settlement-runs',
    component: MDMAAggregationsComonent,
    resolve: {user : () => inject(AuthResolver).resolve()}
  },
  {
    path: 'interval-usage',
    component: IntervalUsageComponent,
    resolve: {user : () => inject(AuthResolver).resolve()}
  },
  {
    path: "spah-overrides",
    component: SpahOverridesComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: "service-points",
    component: ServicePointsComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: "entity-managment",
    component: EntityManagementComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: "profile-segment",
    component: ProfileSegmentComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: "scalar-profiling",
    component: ScalarProfilingComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: "planning-year",
    component: PlanningYearComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  },
  {
    path: 'dashboard-poc',
    component: DashboardPocComponent,
    resolve: {user : () => inject(AuthResolver).resolve()},
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
