import {
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TableHeaderSortButtonComponent } from "./table-header-sort-button/table-header-sort-button.component";
import { TableHeaderFilterButton } from "./table-header-filter-button/table-header-filter-button.component";
import { TableHeaderFilterPanelComponent } from "./table-header-filter-panel/table-header-filter-panel.component";
import { FilterDateState } from "src/app/shared/utilities/filter-date-state";
import { Subscription } from "rxjs";
import { CurrentSort, TableSortService } from "../services/table-sort.service";
import { TableFilterService } from "../services/table-filter.service";
import { IHeaderParams } from "ag-grid-community";
import { DateFilterComponent } from "../filters/date-filter/date-filter.component";
import { NamedDate } from "src/app/shared/models/namedDate";

type DateHeaderComponentParams = {
  filterState?: FilterDateState;
  namedDates?: NamedDate[];
}

@Component({
  standalone: true,
  imports: [
    TableHeaderSortButtonComponent,
    TableHeaderFilterButton,
    TableHeaderFilterPanelComponent,
    DateFilterComponent,
  ],
  providers: [],
  styles: `
        :host {
            position: relative;
            display: grid;
            grid-template-columns: 1fr minmax(0, 1.5rem);
            gap: 0.25rem;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 700;
            width: 100%;
        }
    `,
  template: `
    <button
      app-table-header-sort-button
      [label]="headerName"
      [currentSort]="currentSort"
      (click)="sort($event)"
    ></button>

    @if(filterable) {
    <button
      app-table-header-filter-button
      [filterCount]="filterCount"
      (click)="filterService.toggle(field)"
    ></button>
    }

    @if(filterState) {
      <div app-table-header-filter-panel [show]="isFilterOpen" [width]="'15rem'">
        <app-date-filter [state]="filterState" [namedDates]="params.namedDates"></app-date-filter>
      </div>
    }
  `,
})
export class DateHeaderComponent<DATA extends Record<string, unknown>>
  implements OnDestroy, OnInit
{
  private elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
  private sortService = inject(TableSortService);
  public filterService = inject(TableFilterService);


  private subscription = new Subscription();

  public params: IHeaderParams<DATA> & DateHeaderComponentParams;
  public headerName: string = "";
  public field: string = "";
  public sortable: boolean = false;
  public filterable: boolean = false;
  public currentSort: CurrentSort | undefined = undefined;
  public filterCount: number = 0;
  public isFilterOpen: boolean = false;
  public filterState?: FilterDateState<DATA>;

  static openedHeaderField: string | undefined;

  agInit(params: IHeaderParams<DATA> & DateHeaderComponentParams): void {
    this.refresh(params);
  }

  refresh(params: IHeaderParams<DATA> & DateHeaderComponentParams): boolean {
    this.params = params;
    if(params.filterState && !this.filterState) {
      this.filterState = params.filterState
    }
    this.headerName = params.column.getColDef().headerName ?? "";
    this.field = params.column.getColDef().field ?? "";
    this.sortable = params.column.isSortable();
    this.filterable = params.column.isFilterAllowed();
    return true;
  }

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.isFilterOpen &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.filterService.toggle(this.field);
    }
  }

  ngOnInit(): void {
    if(this.filterState) {
      this.subscription.add(
        this.filterState.state$.subscribe((s) => {
          this.filterCount = !!s.date && !!s.operator ? 1 : 0;
        })
      );
      this.subscription.add(
        this.filterState.params$.subscribe((p) => {
          this.filterService.update(p);
        })
      );
    }
    this.subscription.add(
      this.filterService.isOpen(this.field).subscribe((isOpen) => {
        this.isFilterOpen = isOpen;
      })
    );
    this.subscription.add(
      this.sortService.getCurrentSort(this.field).subscribe((cs) => {
        this.currentSort = cs;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sort(evt: MouseEvent) {
    if (this.sortable) {
      this.sortService.next({
        name: this.field,
        multi: evt.ctrlKey,
      });
    }
  }
}
