import { ColDef } from "ag-grid-community";
import { FlagCellComponent } from "../cells/flag-cell.component";
import { BasicEntityRefCellComponent } from "../cells/basic-entity-code-cell.component";
import { DateCellComponent } from "../cells/date-cell.component";
import { ServicePointCellComponent } from "../cells/service-point-cell.component";
import { DateHeaderComponent } from "../headers/date-header.component";
import { EntityCodeHeaderComponent } from "../headers/entity-code-header.component";
import { FlagHeaderComponent } from "../headers/flag-header.component";
import { ServicePointHeaderComponent } from "../headers/service-point-header.component";
import { NON_TERMINATING_NAMED_DATE } from "src/app/shared/models/namedDate";

export class ServicePointAttributeConfirmationColumnDefinition {
  constructor(
  ) {}

  sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Service Point",
      field: "service_point_id",
      cellRenderer: ServicePointCellComponent,
      headerComponent: ServicePointHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Effective Date",
      field: "effective_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,

    },
    {
      ...this.sharedDef,
      headerName: "Termination Date",
      field: "termination_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        namedDates: [NON_TERMINATING_NAMED_DATE],
      },
    },
    {
      ...this.sharedDef,
      headerName: "LDC",
      field: "ldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "ELDC",
      field: "eldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Profile Class",
      field: "profile_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Retailer",
      field: "retailer_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Supply Class",
      field: "supply_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Meter Type",
      field: "meter_type_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Loss Class",
      field: "loss_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Location",
      field: "location_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "CP Node",
      field: "cpnode_code",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Weather Sensitive",
      field: "weather_sensitive_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "BMG",
      field: "bmg_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Tie",
      field: "tie_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "New Meter Rider",
      field: "net_meter_rider_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Usage Ingestion Block",
      field: "usage_ingestion_block_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "SPA Override",
      field: "spa_override_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
    },
  ];
}
