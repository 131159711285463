import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-modal-footer]",
  host: {
    class: "modal-footer",
  },
  template: `<ng-content></ng-content>`,
  styles: `
    :host {
        width: 100%;
        display: flex;
        justify-content: end;
        gap: 0.5rem;
    }
  `,
})
export class ModalFooterComponent {}
