import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { FilterParams } from "src/app/shared/utilities/http-params";
import { SortOrder } from "src/app/shared/utilities/table-sort-observable";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
  ],
  selector: "app-interval-usage-export-modal",
  styles: ``,
  template: `
    <div app-modal-header>Export</div>
    <div app-modal-body>
      <div app-labeled-property label="Number of records">Commin Soon!</div>
      <div app-labeled-property label="File size">Commin Soon!</div>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onDownload()" [disabled]="true">Download</button>
    </div>
  `,
})
export class IntervalUsageExportModalComponent {
  activeModal = inject(NgbActiveModal);

  filters: FilterParams[];
  sort: SortOrder;

  init(filters: FilterParams[], sort: SortOrder) {
    this.filters = filters;
    this.sort = sort;
  }

  onDownload() {
  }

  onCancel() {
    this.activeModal.dismiss();
  }
}
