import { ColDef, IRowNode } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { DateHeaderComponent } from "../headers/date-header.component";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import { FilterDateStateFactory } from "src/app/shared/utilities/filter-date-state";
import { FilterListStateFactory } from "src/app/shared/utilities/filter-list-state";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { ServicePointCellComponent } from "../cells/service-point-cell.component";
import { ServicePointHeaderComponent } from "../headers/service-point-header.component";
import { BasicHeaderComponent } from "../headers/basic-header.component";

export class IntervalUsageColumnDefinition {
  constructor(
    private filterDateStateFactory: FilterDateStateFactory<IntervalUsage>,
    private filterListStateFactory: FilterListStateFactory<IntervalUsage>,
    private refDataService: ReferenceDataService,
    private onViewHistoryAction: (data: IntervalUsage) => void,
    private actions: ActionCellProps<IntervalUsage>["actions"]
  ) {}

  public filters = {
    service_point_id: this.filterListStateFactory<ServicePointIdReference>(
      "service_point_id",
      (v) => v.service_point_id
    ),
    date: this.filterDateStateFactory("operating_date"),
  };

  public sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 125,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      minWidth: 165,
      flex: 1,
      headerName: "Service Point",
      field: "service_point_id",
      cellRenderer: ServicePointCellComponent,
      headerComponent: ServicePointHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["service_point_id"],
        fetchServicePoints: this.refDataService.servicePoints.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      headerName: "Date",
      field: "operating_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["date"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Time",
      field: "market_interval",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "HH:mm",
      },
      headerComponent: BasicHeaderComponent,
    },
    {
      ...this.sharedDef,
      headerName: "Usage (kW h)",
      field: "usage",
      cellRenderer: (d: IRowNode<IntervalUsage>) => d.data?.measurement_value.toFixed(2).padStart(10, '\u00a0'),
      onCellClicked: (evt) => this.onViewHistoryAction(evt.data),
      cellStyle: {cursor: 'pointer'}
    },
    {
      ...this.sharedDef,
      width: 175,
      headerName: "Last Updated At",
      field: "last_update_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY-MM-dd HH:mm:ss",
      },
      headerComponent: DateHeaderComponent,
    },
    {
      ...this.sharedDef,
      width: 175,
      headerName: "Last Updated By",
      field: "last_updated_by",
      headerComponent: BasicHeaderComponent,
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      cellRenderer: ActionCellComponent<IntervalUsage>,
      cellRendererParams: {
        actions: this.actions,
      },
    },
  ];
}
