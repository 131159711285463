import { ColDef } from "ag-grid-community";
import { BasicEntityRefCellComponent } from "../cells/basic-entity-code-cell.component";
import { DateCellComponent } from "../cells/date-cell.component";
import { EntityCodeHeaderComponent } from "../headers/entity-code-header.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { DateHeaderComponent } from "../headers/date-header.component";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { MDMAJobStatus } from "../../settlements/service/settlements.types";
import {
  EntityRef,
} from "src/app/shared/models/referenceData";
import { FilterDateStateFactory } from "src/app/shared/utilities/filter-date-state";
import { FilterListStateFactory } from "src/app/shared/utilities/filter-list-state";

export class MDMAAggregationsColumnDefinition {
  constructor(
    private filterDateStateFactory: FilterDateStateFactory<MDMAJobStatus>,
    private filterListStateFactory: FilterListStateFactory<MDMAJobStatus>,
    private refDataService: ReferenceDataService,
    private actions: ActionCellProps<MDMAJobStatus>["actions"]
  ) {}

  public filters = {
    operating_day: this.filterDateStateFactory("operating_day"),
    settlement_type: this.filterListStateFactory<EntityRef>(
      "settlement_type",
      (v) => v.entity_code
    ),
    run_status: this.filterListStateFactory<EntityRef>(
      "run_status",
      (v) => v.entity_code
    ),
    start_time: this.filterDateStateFactory("start_time"),
  };

  public sharedDef: ColDef = {
    filter: true,
    sortable: true,
    editable: false,
    minWidth: 100,
    width: 250,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      flex: 1,
      headerName: "Operating Day",
      field: "operating_day",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["operating_day"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Settlement Type",
      field: "settlement_type",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["settlement_type"],
        fetchEntityRefs: this.refDataService.settlementTypes.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Status",
      field: "run_status",
      cellRenderer: BasicEntityRefCellComponent,
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["run_status"],
        fetchEntityRefs: this.refDataService.runStatuses.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Created On",
      field: "start_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["start_time"],
      },
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      filter: false,
      sortable: false,
      cellRenderer: ActionCellComponent,
      cellRendererParams: {
        actions: this.actions,
      },
    },
  ];
}
