import { Component, inject } from "@angular/core";
import { IntervalUsageTableComponent } from "../table/interval-usage-table.component";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { PageContentComponent } from "../page-content/page-content.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatIcon } from "@angular/material/icon";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpdateIntervalUsageModalComponent } from "../modals/update-interval-usage-modal/update-interval-usage-modal.component";
import { IntervalUsageImportModalComponent } from "../modals/interval-usage-import-modal/interval-usage-import.modal.component";
import { IntervalUsageExportModalComponent } from "../modals/interval-usage-export-modal/interval-usage-export.modal.component";
import { TableFilterService } from "../table/services/table-filter.service";
import { TablePageService } from "../table/services/table-page.service";
import { TableSortService } from "../table/services/table-sort.service";
import { combineLatest, firstValueFrom, lastValueFrom } from "rxjs";

@Component({
  standalone: true,
  imports: [
    MatMenuModule,
    MatIcon,
    PageHeaderComponent,
    PageContentComponent,
    IntervalUsageTableComponent,
  ],
  providers: [TableSortService, TableFilterService, TablePageService],
  selector: "app-interval-usage",
  styles: ``,
  template: ` <app-page-header title="Interval Usage">
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onExportAction()">Export</button>
        <button mat-menu-item (click)="onImportAction()">Import</button>
        <button mat-menu-item (click)="onCreateAction()">Create</button>
      </mat-menu>
      <button
        class="action-btn icon"
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon svgIcon="setting-list"></mat-icon>
      </button>
    </app-page-header>
    <app-page-content>
      <app-interval-usage-table></app-interval-usage-table>
    </app-page-content>`,
})
export class IntervalUsageComponent {
  modalService = inject(NgbModal);
  filterService = inject(TableFilterService);
  sortService = inject(TableSortService);

  onCreateAction() {
    this.modalService
      .open(UpdateIntervalUsageModalComponent, { centered: true })
      .componentInstance.init();
  }

  async onExportAction() {
    const [filters, sort] = await firstValueFrom(
      combineLatest([this.filterService.state$, this.sortService.getOrder()])
    );

    this.modalService
      .open(IntervalUsageExportModalComponent, { centered: true })
      .componentInstance.init(filters, sort);
  }

  onImportAction() {
    this.modalService.open(IntervalUsageImportModalComponent, {
      centered: true,
    });
  }
}
