import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { BifoldComponent } from "../../bifold/bifold.component";
import { RightAlignComponent } from "../../right-align/right-align.component";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    BifoldComponent,
    RightAlignComponent
  ],
  selector: "app-update-interval-usage-modal",
  styles: ``,
  template: `
    <div app-modal-header>Update Usage</div>
    <div app-modal-body>
      <div app-bifold>
        <div>
          <div app-labeled-property label="Service Point">Commin Soon!</div>
          <div app-labeled-property label="Date">Commin Soon!</div>
        </div>
        <div app-right-align>
          <button class="action-btn" (click)="onClear()" [disabled]="true">Clear Usage</button>
        </div>
      </div>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onUpdate()" [disabled]="true">Update</button>
    </div>
  `,
})
export class UpdateIntervalUsageModalComponent {
  activeModal = inject(NgbActiveModal);

  init(servicePointId?: string, date?: string) {
    console.log({ servicePointId, date });
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onUpdate() {
    console.log('Update Triggered!')
  }

  onClear() {
    console.log('Clear Triggered!')
  }
}
