import { Component, inject, OnDestroy } from "@angular/core";
import {
  catchError,
  combineLatest,
  NEVER,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableSortService } from "./services/table-sort.service";
import { TableFilterService } from "./services/table-filter.service";
import { FILTER_DATE_STATE_FACTORY } from "src/app/shared/utilities/filter-date-state";
import { FILTER_LIST_STATE_FACTORY } from "src/app/shared/utilities/filter-list-state";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { IntervalUsageColumnDefinition } from "./definitions/interval-usage-columns-definition";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { IntervalUsageService } from "../interval-usage/interval-usage.service";
import {
  TablePageService,
  TablePageState,
} from "./services/table-page.service";
import { IntervalUsageResponse } from "src/app/shared/models/intervalUsageResponse";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IntervalUsageHistoryModalComponent } from "../modals/interval-usage-history-modal/interval-usage-history-modal.component";
import { UpdateIntervalUsageModalComponent } from "../modals/update-interval-usage-modal/update-interval-usage-modal.component";

@Component({
  standalone: true,
  selector: "app-interval-usage-table",
  imports: [AgGridAngular, TablePaginationComponent, ActiveFilterListComponent],
  styles: `
      :host {
          display: flex;
          flex-direction: column;
          justify-content: start;
      }
      app-table-pagination {
        margin-top: 1rem;
    }
    `,
  template: `
    <app-active-filter-list [columnDefs]="columnDefs"></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 70vh"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageState?.pageNumber ?? 0"
      [pageSize]="pageState?.pageSize ?? 0"
      [totalResults]="pageState?.total ?? 0"
      (pageNumberChange)="pageService.pageNumber$.next($event)"
      (pageSizeChange)="pageService.pageSize$.next($event)"
    ></app-table-pagination>
  `,
})
export class IntervalUsageTableComponent implements OnDestroy {
  modalService = inject(NgbModal);
  pageService = inject(TablePageService);
  sortService = inject(TableSortService);
  filterService = inject(TableFilterService);
  intervalUsageService = inject(IntervalUsageService);
  referenceDataService = inject(ReferenceDataService);
  filterDateStateFactory = inject(FILTER_DATE_STATE_FACTORY);
  filterListStateFactory = inject(FILTER_LIST_STATE_FACTORY);

  private subscription = new Subscription();
  pageState: TablePageState | undefined;

  public gridApi: GridApi;

  getRowId: GetRowIdFunc<IntervalUsage> = (data) =>
    `${data.data.service_point_id}-${data.data.operating_date}-${data.data.market_interval}`;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  private init() {
    this.subscription.add(
      this.pageService.state$.subscribe((pageState) => {
        this.pageState = pageState;
      })
    );

    this.subscription.add(
      combineLatest([
        this.pageService.params$,
        this.filterService.state$,
        this.sortService.getOrder(),
      ])
        .pipe(
          tap(() => {
            this.showLoading();
          }),
          switchMap(([page, filters, order]) =>
            this.intervalUsageService.getIntervalUsage(page, filters, order)
          ),
          catchError(() => {
            this.showError();
            return NEVER;
          })
        )
        .subscribe((res) => {
          this.updateData(res);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clearTable() {
    const nodeData: unknown[] = [];
    this.gridApi?.forEachNode((node) => nodeData.push(node.data));
    this.gridApi?.applyTransaction({
      remove: nodeData,
    });
  }

  showLoading() {
    this.gridApi.showLoadingOverlay();
  }

  updateData(response: IntervalUsageResponse) {
    if (response.total <= 0) {
      return this.showNoData();
    }

    this.pageService.total$.next(response.total);
    const nodeData: unknown[] = [];
    this.gridApi?.forEachNode((node) => nodeData.push(node.data));
    this.gridApi?.applyTransaction({
      remove: nodeData,
      add: response.results,
    });
    this.gridApi.hideOverlay();
  }

  showNoData() {
    this.clearTable();
    this.noRowsOverlayParams = { error: false };
    this.gridApi.showNoRowsOverlay();
  }

  showError() {
    this.clearTable();
    this.noRowsOverlayParams = { error: true };
    this.gridApi.showNoRowsOverlay();
  }

  onViewHistoryAction(data: IntervalUsage) {
    this.modalService
      .open(IntervalUsageHistoryModalComponent, {centered: true})
      .componentInstance.init(data.service_point_id, data.operating_date, data.market_interval);
  }

  onEditAction(data: IntervalUsage) {
    this.modalService
      .open(UpdateIntervalUsageModalComponent, {centered: true})
      .componentInstance.init(data.service_point_id, data.operating_date);
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };

  tableColumnDef = new IntervalUsageColumnDefinition(
    this.filterDateStateFactory,
    this.filterListStateFactory,
    this.referenceDataService,
    this.onViewHistoryAction.bind(this),
    [
      {
        name: "Edit",
        onAction: this.onEditAction.bind(this),
      },
    ]
  );
  public columnDefs: ColDef[] = this.tableColumnDef.getColDefs();
}
