import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  Observable,
  of,
  scan,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from "rxjs";

export type CachedFilterSearchMeta = {
  searchTerm: string;
  searching: boolean;
  hasMore: boolean;
  error: boolean;
  count: number;
};

export class CachedFilterSearchState<T> {
  private searchTerm$ = new Subject<string>();
  private metaTrigger$ = new BehaviorSubject<Partial<CachedFilterSearchMeta>>({
    searchTerm: "",
    searching: false,
    error: false,
    count: 0,
  });

  public results$ = this.searchTerm$.asObservable().pipe(
    tap((searchTerm) => {
      shareReplay(1),
      distinctUntilChanged(),
      this.metaTrigger$.next({
        searchTerm: searchTerm,
        searching: true,
        error: false,
        count: 0,
      });
    }),
    switchMap((searchTerm) => this.fetch(searchTerm)),
    tap(() => {
      this.metaTrigger$.next({
        searching: false,
        error: false,
      });
    }),
    startWith(<T[]>[]),

    catchError((e) => {
      this.metaTrigger$.next({
        searching: false,
        error: true,
      });
      return of([]);
    }),
    tap((results) => {
      this.metaTrigger$.next({
        count: results.length,
      });
    }),
    shareReplay(1)
  );

  public meta$ = this.metaTrigger$.asObservable().pipe(
    scan((acc, next) => ({ ...acc, ...next }), <CachedFilterSearchMeta>{
      searchTerm: "",
      searching: false,
      error: false,
      count: 0,
    })
  );

  constructor(private fetch: (searchTerm: string) => Observable<T[]>) {}

  public search(term: string) {
    this.searchTerm$.next(term ?? '');
  }
}
