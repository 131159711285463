import { Component } from "@angular/core";

@Component({
    standalone: true,
    selector: '[app-bifold]',
    styles: `
    :host {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }`,
    template: '<ng-content></ng-content>'
})
export class BifoldComponent {}